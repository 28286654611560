import {colors} from "../primitives/colors";
import React from "react";
import Typography from "../primitives/typography";
import { motion } from "framer-motion";
import {ProductRenderDisplayVariant} from "../../importer/customizer/utils/get-variants-for-display";
import MockedAsset from "./mocked-asset";
import {useOnClickOutside} from "usehooks-ts";


const ColorTitle = ({ image, title, showPill, pillText }) => (
    <div css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div css={{
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '30px',
            borderRadius: '500px',
            overflow: 'hidden',
            marginRight: '10px',
            flex: 'none',
            marginLeft: '-3px'
        }}>
            <div css={{
                borderRadius: '500px',
                display: 'block',
                overflow: 'hidden',
                border: `1px solid ${colors.shadesWhite}`,
                padding: '0px',
                width: '30px',
                height: '30px',
                transition: 'border 0.2s',
                '[role="group"]:hover &': {
                    border: `1px solid ${colors.neutral10}`,
                },
            }}>
                <MockedAsset css={{
                    borderRadius: '500px',
                    transform: 'translateZ(0px)',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                }} asset={image} />
            </div>
        </div>
        <Typography fontSize={{
            dt: 'p1'
        }} css={{
            paddingRight: '14px'
        }}>
            {title}
        </Typography>
        {showPill && (
            <Typography css={{
                padding: '3px 10px',
                borderRadius: '4px',
            }} as="div" color="neutral10" backgroundColor="tertiaryBlue2" fontSize={{
                dt: 'p2'
            }}>
                {pillText}
            </Typography>
        )}
    </div>
)


const BaseComboBox = ({ valueIndex, setValueIndex, items }: {
    valueIndex: number
    setValueIndex: React.Dispatch<React.SetStateAction<number>>
    items: { value: string, node: React.ReactNode }[]
}) => {
    const [open, _setOpen] = React.useState(false)

    const [currentStartIndexOfView, setCurrentStartIndexOfView] = React.useState(() => Math.min(Math.max(valueIndex - 2, 0), Math.max(items.length - 5, 0)))

    const [frozenStartPosition, setFrozenStartPosition] = React.useState(0)

    const calculateAndSetStartIndex = (wantedIndex: number) => {
        setCurrentStartIndexOfView(Math.min(Math.max(wantedIndex - 2, 0), Math.max(items.length - 5, 0)))
    }

    const maxItemsInView = 5;

    const currentViewportChildren = items.map((x, idx) => ({ node: x.node, realIndex: idx })).slice(currentStartIndexOfView, currentStartIndexOfView + maxItemsInView)

    const foundWrapIndex = currentViewportChildren.findIndex(x => x.realIndex === valueIndex)

    const hasItemsAbove = currentStartIndexOfView > 0;
    const hasItemsBelow = currentViewportChildren[currentViewportChildren.length-1].realIndex < (items.length -1)

    const timerRef = React.useRef<NodeJS.Timeout>(null)
    const currentChildrenIndexesRef = React.useRef(currentViewportChildren)

    currentChildrenIndexesRef.current = currentViewportChildren;

    const clearTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
        }
    }

    const helpDirection = (dir: number) => {
        if (dir === -1) {
            calculateAndSetStartIndex(currentChildrenIndexesRef.current[0].realIndex + 1)
        } else {
            calculateAndSetStartIndex(currentChildrenIndexesRef.current[0].realIndex + 3)
        }
    }

    const startTimerUp = () => {
        clearTimer()
        timerRef.current = setInterval(() => {
            helpDirection(-1)
        }, 200)
    }

    const startTimerDown = () => {
        clearTimer()
        timerRef.current = setInterval(() => {
            helpDirection(1)
        }, 200)
    }

    const setOpen = (state: boolean) => {
        if (!state) {
            calculateAndSetStartIndex(valueIndex)
            setFrozenStartPosition(0)
        } else {
            let initial = foundWrapIndex * 38
            setFrozenStartPosition(initial)
        }
        _setOpen(state)


    }

    const ref = React.useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => setOpen(false))


    return (
        <div ref={ref} css={{
            zIndex: open ? 10 : 1,
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '40px',
            transform: (() => {
                if (open) {
                    let initial = frozenStartPosition;
                    if (hasItemsAbove) {
                        initial += 20
                    }
                    if (hasItemsBelow) {
                        //initial += 20
                    }
                    return `translateY(-${initial}px)`
                }
                return 'translateY(0)'
            })()
        }}>
            <motion.div css={{
                width: '100%',
                top: '0',
                left: '0',
                backgroundColor: colors.shadesWhite,
                boxShadow: '6px 6px 10px #0000',
                border: `1px solid ${colors.neutral5}`,
                position: 'absolute',
            }} role="combobox"  animate={{
                boxShadow: open ? '6px 6px 10px #0001' : '6px 6px 10px #0000',
                border: open ? `1px solid ${colors.shadesWhite}` : `1px solid ${colors.neutral5}`,
            }} style={{
                borderRadius:  open ? '6px' : '24px',
                height: open ? 'auto' :  '40px',
            }}>
                {(hasItemsAbove && open) && (
                    <motion.button  css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxHeight: '20px',
                        minHeight: '20px',
                        transition: 'background-color 0.2s',
                        borderTopLeftRadius:  '6px',
                        borderTopRightRadius: '6px',
                        '&:hover': {
                            backgroundColor: colors.secondaryBeige3
                        }
                    }} role="group"
                             onClick={() => {
                                 calculateAndSetStartIndex(currentViewportChildren[0].realIndex + 1)
                             }}
                                    onMouseOver={() => {
                                        startTimerUp()
                                    }}
                                    animate={{ opacity: 1,  y: 0,}}

                                    initial={{opacity: 0, y: 4}}
                             exit={{ opacity: 0}}
                    >
                        <svg css={{
                            transform: 'rotate(180deg)'
                        }} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.2002 0.599609L6.0002 5.39961L10.8002 0.599609" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </motion.button>
                )}
                {currentViewportChildren.map((child, idx) => {
                    if (!open && child.realIndex !== valueIndex) {
                        return null;
                    }

                    const [tl, tr, bl, br] = (() => {

                        if (open) {
                            if (idx === 0) {
                                if (!hasItemsAbove) {
                                    return ['6px', '6px', '0px', '0px']
                                }
                            }
                            if (idx === currentViewportChildren.length - 1) {
                                if (!hasItemsBelow) {
                                    return ['0px', '0px', '6px', '6px']
                                }
                            }
                            return ['0px', '0px', '0px', '0px']
                        }

                        return ['24px', '24px', '24px', '24px']
                    })()

                    return (
                        <motion.button key={idx} css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '6px 12px',
                            width: '100%',
                            maxHeight: '38px',
                            minHeight: '38px',
                            transition: 'background-color 0.2s',
                            '&:hover': {
                                backgroundColor: colors.secondaryBeige3
                            }
                        }} role="group" onClick={() => {
                            setOpen(!open)
                            if (open) {
                                setValueIndex(idx + currentStartIndexOfView)
                                calculateAndSetStartIndex(idx + currentStartIndexOfView)
                            }
                        }}
                                       initial={{opacity: 0, y: 4}}
                                       animate={{ opacity: 1, y: 0, borderTopLeftRadius: tl,
                                           borderTopRightRadius: tr,
                                           borderBottomLeftRadius: bl,
                                           borderBottomRightRadius: br, }}
                                       exit={{ opacity: 0}}
                        >
                            {child.node}
                            <motion.div css={{
                                marginRight: '5px'
                            }} animate={{
                                opacity: open ? 0 : 1,
                                y: open ?  4 : 0
                            }}>
                                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.2002 0.599609L6.0002 5.39961L10.8002 0.599609" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                            </motion.div>
                        </motion.button>
                    )
                })}
                {(hasItemsBelow && open) && (
                    <motion.button  css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        maxHeight: '20px',
                        minHeight: '20px',
                        transition: 'background-color 0.2s',
                        borderBottomLeftRadius:  '6px',
                        borderBottomRightRadius: '6px',
                        '&:hover': {
                            backgroundColor: colors.secondaryBeige3
                        }
                    }} role="group"
                             onClick={() => {
                                 calculateAndSetStartIndex(currentViewportChildren[0].realIndex + 3)
                             }}
                                    onMouseOver={() => {
                                        startTimerDown()
                                    }}
                             initial={{opacity: 0, y: 4}}
                             animate={{ opacity: 1,  y: 0,}}
                             exit={{ opacity: 0}}
                    >
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.2002 0.599609L6.0002 5.39961L10.8002 0.599609" stroke="#1A1A1A" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </motion.button>
                )}
            </motion.div>
            <select css={{
                zIndex: 2,
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: 0,
                display: 'none',
                '@media (pointer: coarse)': {
                    display: 'block'
                }
            }} onChange={(e) => {
                const foundIndex = items.findIndex(x => x.value === e.target.value)
                if (foundIndex !== -1) {
                    setValueIndex(foundIndex)
                    calculateAndSetStartIndex(foundIndex)
                }
            }} value={items[valueIndex].value}>
                {items.map((item) => (
                    <option css={{
                        opacity: 0,
                    }} value={item.value}>{item.value}</option>
                ))}
            </select>
        </div>
    )
}

export const SortSelector = ({ sorts, value, setValue }: { sorts: { label: string, value: string }[], value: string, setValue: React.Dispatch<React.SetStateAction<string>> }) => {

    const valueIndex = sorts.findIndex(x => x.value === value)

    const setValueIndex = (e: number) => {
        setValue(sorts[e].value)
    }


    return (
        <BaseComboBox valueIndex={valueIndex} setValueIndex={setValueIndex} items={sorts.map((sort, idx) => {
            return {
                value: sort.label,
                node: (
                    <Typography fontSize={{
                        dt: "p"
                    }} css={{
                        paddingLeft: '8px'
                    }} color="neutral10">
                        {sort.label}
                    </Typography>
                )
            }
        })}/>
    )
}
const SwatchSelector = ({ variants, colorIndex, setColorIndex }: { variants: ProductRenderDisplayVariant[], colorIndex: number, setColorIndex: React.Dispatch<React.SetStateAction<number>>, }) => {


    return (
        <BaseComboBox valueIndex={colorIndex} setValueIndex={setColorIndex} items={variants.map((color, idx) => {
            return {
                value: color.colorTitle,
                node: (
                    <ColorTitle title={`${color.colorTitle}`} image={color.image} showPill={color.showPill} pillText={color.pillText} />
                )
            }
        })} />
    )
}

export default SwatchSelector
